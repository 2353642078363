@import './vars';

.List-Container {
  overflow-y: scroll;
  height: 300px !important;
  padding-bottom: 24px;
}

.List-Container:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 247, 253, 1) 90%);
  width: 100%;
  height: 3em;
}

.list-name {
  font-size: 16px;
  font-weight: 600;
}
