@import './vars';

img {
  width: 300px;
}

.ListItem {
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.ListItem-Row {
  display: flex;
  background-color: $white;
  border-radius: 10px;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
}

.ListItem-Col-1 {
  width: 50px;
  color: $white;
  font-weight: 700;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  text-align: center !important;
  padding: 8px;
}

.ListItem-Col-2 {
  padding: 10px;
  color: $base-text-color;
}

.ListItem-Col-3 {
  width: 50px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: $base-anchor-color;

  a {
    color: $base-anchor-color;
  }

  a:hover {
    color: #888888;
  }

  .dropdown-item {
    color: $base-text-color;
  }

  .delete {
    color: $danger;
  }

  .delete:hover {
    color: #a90027;
  }
}
