@import './vars';

.search {
  border: none;
  border-radius: 0;
  // width: 100% !important;
  margin-top: 8px;
}

.navbar-logo {
  display: none;
}

.navbar-collapse {
  display: none;
}

.prepend {
  background: white;
  border-radius: 0;
  border: none;
  margin-top: 8px;
}

.input-group {
  width: 200px;
}

.navbar {
  background: white;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(231, 231, 231, 0.5);
  -moz-box-shadow: 0px 2px 10px 0px rgba(231, 231, 231, 0.5);
  box-shadow: 0px 2px 10px 0px rgba(231, 231, 231, 0.5);
}

.navbar-expand-lg .navbar-collapse {
  display: none !important;
}

.sidebar-logo {
  display: block;
  margin: 0 auto;
  padding-top: 8px;
}

.icon-nav {
  margin-top: 32px;

  .nav-link {
    padding: 0;
    text-align: center;
    color: $base-anchor-color;
  }

  .nav {
    padding-left: 0 !important;
  }

  .nav-item {
    padding: 16px;
  }
}

.sidebar-nav {
  padding: 0;
}

.active {
  color: $digital-blue !important;
  // border-right: 3px solid $digital-blue;
}

.date-picker {
  max-width: 150px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-style: outset;
  border-color: gray;
  border-image: none;
}

.barSelector {
  height: 60px;
}

.breadcrumb-title {
  font-weight: 500;
  margin-right: 8px;
}

.checkin-bg {
  background: $background-color;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .navbar-collapse.show {
    display: block !important;
  }

  #sidebar-wrapper {
    display: none;
  }

  .navbar {
    // background: #404040;
  }

  form.form-inline {
    display: none;
  }

  .navbar-logo {
    display: inline;
    padding-top: 8px;
    max-width: 48px;
  }

  .datepicker {
    max-width: 150px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ced4da;
    border-top-right-radius: 5px;
  }

  .navbar-expand .navbar-toggler {
    display: block;
  }

  .input-group {
    margin-right: 10px;
  }

  .hidden-mobile {
    display: none !important;
  }
}
