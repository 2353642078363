@import './vars';

.sidebar-logo {
  max-width: 48px;
  margin: 0 auto;
}

.hr-break {
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
}

.status-row {
  align-items: center;

  @media screen and (max-width: $large) {
    height: unset;
  }

  @media screen and (min-width: $large) {
    height: 50px;
  }
}

.bar-name {
  margin-bottom: 40px;
}

.status-title {
  font-size: 24px;
}

// .date-picker {
//   padding: 4px;
//   margin-bottom: 8px;
//   border-radius: 10px;
//   border: none;
//   text-align: center;
//   border: 1px solid lightgray;
//   width: 100px;
// }

.date-picker {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px;
  font-size: 1rem;
  line-height: 1.5;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #333333;
  border-color: #333333;
}

.add-list-button {
  margin: 8px;
  padding-bottom: 8px;
  width: 50px;
  height: 50px;
  text-align: center;
  background: $base-orange;
  border: none;
  font-size: 1.5rem;
}

.add-list-button.mobile {
  display: none;
}

.add-list-button:hover {
  background: #cb5f00;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .add-list-button.mobile {
    display: block;
  }
}
