// BarGoat Base Styles

// 1. Colors

$base-orange: #ff7800; // BarGoat Orange
$success: #5cb85c;
$digital-blue: #028bfc;
$warning: #ffdf11;
$danger: #ff003c;

$white: #ffffff;
$black: #000000;
$light-gray: #d8d8d8;

$base-text-color: #555555;
$base-anchor-color: #cdcdcd;
$background-color: #f4f7fd; //#f4f7fd;

$base-green: #5cb85c; // change later

// 2. Media Screen Size
$small: 540px;
$medium: 720px;
$large: 992px;
$x-large: 1140px;
