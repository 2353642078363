@import './vars';

.InfoIcon {
  text-align: center;
  border-radius: 10px;
  background-color: $white;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(231, 231, 231, 1);
  -moz-box-shadow: 0px 5px 10px 0px rgba(231, 231, 231, 1);
  box-shadow: 0px 5px 10px 0px rgba(231, 231, 231, 1);
}

.InfoIcon-Row-1 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $base-orange;
  height: 10px;
}

.InfoIcon-Row-2 {
  color: $base-orange;
  font-size: 40px;
  padding: 10px;
}

.InfoIcon-Row-3 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 24px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .InfoIcon {
    margin-bottom: 30px;
  }
}
