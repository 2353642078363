@import './vars';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@400;700&display=swap');

.split-right {
  height: 100vh;
  background: rgb(255, 120, 0);
  background: -moz-linear-gradient(45deg, rgba(255, 120, 0, 1) 51%, rgba(250, 217, 97, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(255, 120, 0, 1) 51%, rgba(250, 217, 97, 1) 100%);
  background: linear-gradient(45deg, rgba(255, 120, 0, 1) 51%, rgba(250, 217, 97, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7800",endColorstr="#fad961",GradientType=1);
}

.split-left {
  height: 100vh;
}

#form-login {
  margin: 0 auto;
}

.form-heading {
  font-family: 'Bebas Neue', cursive;
  font-size: 2.5rem;
}

.form-split {
  border: solid 0.5px #f2f2f2;
  padding: 0;
  margin: 0;
}

.form-label {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.help-text {
  font-size: 0.875rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.forgot {
  font-size: 0.875rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.login-input {
  background: #f2f2f2;
  border-radius: 10px;
  border: none;
}

.login-btn {
  border-radius: 10px;
  background: #0275d8;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
