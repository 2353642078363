@import './vars';

.no-padding-x {
  padding-left: 0;
  padding-right: 0;
}

.App {
  background-color: $background-color;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  padding: 24px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

#sidebar-wrapper {
  // min-height: 100vh !important;
  // height: 100vh;
  width: 65px;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  background: white;
  border-right: 1px solid #eeeeee;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
  padding: 0;
  // height: 100vh;
  background: $background-color;
}

.full-height {
  height: 100vh;
}
